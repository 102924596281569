import React from 'react'
import jeets from './Images/jeetsdesk.png'

import Jeet from './Images/meet.png'
import { Grid } from '@mui/material'
import Navbar from './NavBar/Navbar'
function Desktop() {
  return (
       <div  className='makeaborder'> 
       <Navbar/>
       <div className='container'>
  
       <img src={jeets} style={{width:"100%"}}/>
      
       <br/><br/>
       <p className='title_where'>Where community, art, and conviction intertwine on-chain.</p>
<Grid container spacing={0}>
    <Grid item md={10} lg={10} xs={12} sm={12}>
    <img src={Jeet} style={{width:"100%"}}/>
    </Grid>
    <Grid item md={2} lg={2} xs={12} sm={12}>
       <br/>  <br/>  <br/>  <br/>
    <p className='title_wheres'>NAME :     &nbsp; &nbsp; &nbsp; &nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;   JEETS ON BTC
    <p className='title_wheres'>CREATOR :     &nbsp; &nbsp; &nbsp; &nbsp;     JEETOSHI
    <p className='title_wheres'>NETWORK :     &nbsp; &nbsp; &nbsp; &nbsp;     ON-CHAIN
    <p className='title_wheres'>ARTIST :     &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;      ANTONIE MINGO
    <p className='title_wheres'>TICKER :     &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;    ? . ? . ?</p>
    
    </p>

    
    </p>
    </p>
    
     </p>
    
</Grid>
</Grid>
  
       </div>
       </div>
  )
}

export default Desktop