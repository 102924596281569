import React, { useRef, useEffect } from 'react';
import { Grid, Divider } from '@mui/material';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Cover from './Images/atb.png';
import pill from './Images/pilo.png';
import Tea from './Images/tea.jpg';
import stud from './Images/stud.png';
import news from './Images/ART3B.jpg';
import gif from './Images/reet.gif';
import number from './Images/number.png';
import text from './Images/text.png';
import Navbar from './NavBar/NavDO';


gsap.registerPlugin(ScrollTrigger);

function Art() {
  const coverRef = useRef(null);
  const formationRef = useRef(null);
  const textUnderRef = useRef(null);
  const teaRef = useRef(null);
  const pillRef = useRef(null);
  const studRef = useRef(null);
  const numberRef = useRef(null);
  const studyRef = useRef(null);
  const textRef = useRef(null);

  useEffect(() => {
    

    gsap.fromTo(formationRef.current, 
      { opacity: 0, y: 100 }, 
      { opacity: 1, y: 0, duration: 1, scrollTrigger: {
        trigger: formationRef.current,
        start: 'top 80%',
        end: 'bottom 60%',
        scrub: true
      }}
    );

    gsap.fromTo(textUnderRef.current, 
      { opacity: 0, x: 100 }, 
      { opacity: 1, x: 0, duration: 1, scrollTrigger: {
        trigger: textUnderRef.current,
        start: 'top 80%',
        end: 'bottom 60%',
        scrub: true
      }}
    );

    gsap.fromTo(teaRef.current, 
      { opacity: 0, x: -100 }, 
      { opacity: 1, x: 0, duration: 1, scrollTrigger: {
        trigger: teaRef.current,
        start: 'top 80%',
        end: 'bottom 60%',
        scrub: true
      }}
    );

    gsap.fromTo(pillRef.current, 
      { opacity: 0, x: 100 }, 
      { opacity: 1, x: 0, duration: 1, scrollTrigger: {
        trigger: pillRef.current,
        start: 'top 80%',
        end: 'bottom 60%',
        scrub: true
      }}
    );

    gsap.fromTo(studRef.current, 
      { opacity: 0, y: 100 }, 
      { opacity: 1, y: 0, duration: 1, scrollTrigger: {
        trigger: studRef.current,
        start: 'top 80%',
        end: 'bottom 60%',
        scrub: true
      }}
    );

    gsap.fromTo(numberRef.current, 
      { opacity: 0, x: -100 }, 
      { opacity: 1, x: 0, duration: 1, scrollTrigger: {
        trigger: numberRef.current,
        start: 'top 80%',
        end: 'bottom 60%',
        scrub: true
      }}
    );

    gsap.fromTo(studyRef.current, 
      { opacity: 0, y: 100 }, 
      { opacity: 1, y: 0, duration: 1, scrollTrigger: {
        trigger: studyRef.current,
        start: 'top 80%',
        end: 'bottom 60%',
        scrub: true
      }}
    );

    gsap.fromTo(textRef.current, 
      { opacity: 0, x: 100 }, 
      { opacity: 1, x: 0, duration: 1, scrollTrigger: {
        trigger: textRef.current,
        start: 'top 80%',
        end: 'bottom 60%',
        scrub: true
      }}
    );
  }, []);

  return (
    <div className='makeaborder'>
      <Navbar /> 
      <div>
        <Grid container spacing={0}>
          <Grid item md={12} xs={12} lg={12} sm={12} className='centeritall' ref={coverRef}>
            <img src={Cover} style={{width:"100%"}} className='bordertotop_art_image'/>
          </Grid>
        </Grid>
        <div className='container'>
          <Grid container spacing={1}>
             {/* <Grid item md={12} xs={12} lg={12} sm={12} ref={formationRef}>
              <br/>
              <h1 className='form_text'>*The Formation*</h1>
            </Grid>
            <Grid item md={12} xs={12} lg={12} sm={12} ref={textUnderRef}>
              <h1 className='form_text_under'>
                With a team of highly committed developers and exceptionally skilled artists, 
                we are deeply invested in the "Art on Bitcoin" venture, friend. Our characters are 
                crafted in a truly unique manner, incorporating hundreds of distinct features that 
                intertwine IRL and WEB3 allusions throughout. And as for our artists? They're elite, 
                featuring upcoming talents like Korou and the renowned Antoine Mingo, creator of the 
                <span className='form_text_under_in'>Pudgy Penguins</span>.
              </h1>
            </Grid>
            <Grid item md={12} xs={12} lg={12} sm={12} className='centeritalls'>
              <br/><br/><br/>
              <Divider variant="middle" component="li" className='dividers' sx={{ '::before, ::after': { content: 'none' } }}/>
            </Grid> */}
           
            <br/><br/><br/><br/><br/><br/>
            <Grid item md={4} xs={6} lg={4} sm={6} className='centeritalls' ref={teaRef}>
              <img src={Tea} style={{width:"100%", position:"relative" , top:"142px" }} />
            </Grid>
            <Grid item md={4} xs={6} lg={4} sm={6} className='centeritalls' ref={pillRef}>
              
              <img src={gif} style={{width:"80%", position:"relative" , top:"22px" , border:"7px solid #000" }} />
            </Grid>
            <Grid item md={4} xs={6} lg={4} sm={6} className='centeritalls' ref={pillRef}>
              
              <img src={pill} style={{width:"100%" }} />
            </Grid>
            <br/>  <br/>  <br/>  <br/>  <br/>  <br/>  <br/>  <br/>  <br/>  <br/>  <br/>  <br/>  <br/>  <br/>  <br/>  <br/>  <br/>  <br/>  <br/>  <br/>  <br/>  <br/>  <br/>  <br/>  <br/>
            <Grid item md={12} xs={12} lg={12} sm={12} className='centeritalls' ref={studRef}>
            <br/>  <br/>  <br/>  <br/>    <br/>  <br/>  <br/>  <br/>  <br/>  <br/>  <br/>  <br/>  <br/>  <br/>  <br/>  <br/>  <br/>  <br/>  <br/><img src={news} style={{width:"100%" }} />
            </Grid>

            <Grid item md={12} xs={12} lg={12} sm={12} className='centeritalls' ref={studRef}>
              <img src={stud} style={{width:"80%" }} />
            </Grid>
          
             {/*   <Grid item md={2} xs={2} lg={2} sm={2} className='centeritalls' ref={numberRef}>
              <br/><br/><br/><br/><br/><br/>
              <img src={number} style={{width:"80%" }} />
            </Grid> <Grid item md={8} xs={8} lg={8} sm={8} className='centeritalls' ref={studyRef}>
              <h1 className='study_efficiency'>STUDY EFFICIENCY</h1>
            </Grid>
            <Grid item md={2} xs={2} lg={2} sm={2} className='centeritalls'></Grid>
            <Grid item md={12} xs={12} lg={12} sm={12} className='centeritalls' ref={textRef}>
              <br/>
              <img src={text} style={{width:"100%" }} />
            </Grid> */}
           
          </Grid>
        </div>
        <br/><br/><br/>
      </div>
    </div>
  );
}

export default Art;
