import React, { useEffect, useRef } from 'react';
import { Grid } from '@mui/material';
import { gsap } from 'gsap';
import Cover from './Images/atb.png';
import pill from './Images/pilo.png';
import Tea from './Images/tea.jpg';
import stud from './Images/stud.png';
import number from './Images/number.png';
import text from './Images/numbermobile.png';
import Navbar from './NavBar/NavMO'
import news from './Images/ART3B.jpg';
import gif from './Images/reet.gif';
function Art() {
  const coverRef = useRef(null);
  const teaRef = useRef(null);
  const pillRef = useRef(null);
  const studRef = useRef(null);
  const numberRef = useRef(null);
  const textRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    const tl = gsap.timeline({ defaults: { ease: 'power1.out' } });

    tl.from(containerRef.current, { opacity: 0, duration: 1 })
      .from(coverRef.current, { opacity: 0, y: -50, duration: 1 })
      .from('.form_textm', { opacity: 0, y: -50, duration: 1 }, '-=0.5')
      .from('.form_text_underm', { opacity: 0, y: -50, duration: 1 }, '-=0.5')
      .from('.dividersm', { opacity: 0, duration: 1 }, '-=0.5')
      .from(teaRef.current, { opacity: 0, x: -50, duration: 1 }, '-=0.5')
      .from(pillRef.current, { opacity: 0, x: 50, duration: 1 }, '-=1')
      .from(studRef.current, { opacity: 0, y: 50, duration: 1 }, '-=0.5')
      .from(numberRef.current, { opacity: 0, x: -50, duration: 1 }, '-=0.5')
      .from('.study_efficiencym', { opacity: 0, y: -50, duration: 1 }, '-=0.5')
      .from(textRef.current, { opacity: 0, y: 50, duration: 1 }, '-=0.5');
  }, []);

  return (
    <div className="makeaborderm" ref={containerRef}>
      <Navbar />
      <div>
        <Grid container spacing={0}>
          <Grid item md={12} xs={12} lg={12} sm={12} className="centeritall">
            <img ref={coverRef} src={Cover} style={{ width: "100%" }} className="bordertotop_art_image" />
          </Grid>
        </Grid>
        <div>
          <div className="container">
            <Grid container spacing={1}>
 {/*  <Grid item md={12} xs={12} lg={12} sm={12}>
                <br />
                <h1 className="form_textm">*The Formation*</h1>
              </Grid>
              <Grid item md={12} xs={12} lg={12} sm={12}>
                <h1 className="form_text_underm">
                  With a team of highly committed developers and exceptionally skilled artists, we are deeply invested in the "Art on Bitcoin" venture, friend. Our characters are crafted in a truly unique manner, incorporating hundreds of distinct features that intertwine IRL and WEB3 allusions throughout. And as for our artists? They're elite, featuring upcoming talents like Korou and the renowned Antoine Mingo, creator of the <p className="form_text_under_inm">Pudgy Penguins</p>
                </h1>
              </Grid>
              <Grid item md={12} xs={12} lg={12} sm={12} className="centeritall">
                <hr className="dividersm"></hr>
              </Grid> */}

             
              <br />
              <Grid item md={4} xs={4} lg={4} sm={4} className="centeritalls">
                <img ref={teaRef} src={Tea} style={{ width: "100%", position: "relative", top: "40px" }} />
              </Grid> 
              <Grid item md={4} xs={4} lg={4} sm={4} className="centeritalls">
                <img  src={gif} style={{ width: "90%", position: "relative", top: "5px" }} />
              </Grid>
              <Grid item md={4} xs={4} lg={4} sm={4} className="centeritalls">
                <img ref={pillRef} src={pill} style={{ width: "100%" }} />
              </Grid>
              <Grid item md={12} xs={12} lg={12} sm={12} className="centeritalls">
                <img  src={news} style={{ width: "100%" }} />
              </Grid>
              <Grid item md={12} xs={12} lg={12} sm={12} className="centeritalls">
                <img ref={studRef} src={stud} style={{ width: "100%" }} />
              </Grid>
              <Grid item md={2} xs={2} lg={2} sm={2} className="centeritalls">
                 {/*  <img ref={numberRef} src={number} style={{ width: "100%" }} />  <Grid item md={8} xs={8} lg={8} sm={8} className="centeritalls">
                <h1 className="study_efficiencym">STUDY EFFICIENCY</h1>
              </Grid>
              <Grid item md={2} xs={2} lg={2} sm={2} className="centeritalls"></Grid>
              <Grid item md={12} xs={12} lg={12} sm={12} className="centeritalls">
                <br />
                <img ref={textRef} src={text} style={{ width: "100%" }} />
              </Grid> */}
               
              </Grid>
            
            </Grid>
          </div>
          <br /><br />
        </div>
      </div>
    </div>
  );
}

export default Art;
