import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { BsX, BsList } from 'react-icons/bs'; // Import close and burger icons
import { Grid } from '@mui/material';
import node from './node.gif'
import { HiDotsHorizontal } from "react-icons/hi";
import { FaTwitter } from "react-icons/fa";
function CollapsibleExample() {
  const [expanded, setExpanded] = useState(true);

  const toggleNavbar = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
    
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); // Empty dependency array to run this effect only once on component mount

  return (
    <Navbar
      collapseOnSelect
      expand=""
      expanded={expanded}
      className="bg-body-tertiary NAVBAR_STATIC"
    >
      <>
        <Navbar.Brand href="./">
        <img src={node} style={{width:"100px"}}/>
        </Navbar.Brand>
        <Navbar.Toggle
          onClick={toggleNavbar}
          aria-controls="responsive-navbar-nav"
        >
          {/* Burger icon */}
          {!expanded ? <BsList className='buttons_navbar' /> : <BsX   className='buttons_navbar'/>}
        </Navbar.Toggle>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            {/* Add your me-auto links here */}
          </Nav>
          <Nav >
            <Grid container >
              <Grid item md={6} lg={6} xs={12} sm={12}>
              </Grid>
              <Grid item md={6} lg={6} xs={12} sm={12}>
                <Nav.Link href="./" className='buttons_navbarm'>HOME</Nav.Link>
                <hr class="hr" />
                <Nav.Link href="" className='buttons_navbarm'>
                <HiDotsHorizontal style={{fontSize:"40px"}}/>
                </Nav.Link>
                <hr class="hr" />
                <Nav.Link eventKey={2} href="/ART" className='buttons_navbarsm'>GALLERY</Nav.Link>
                <hr class="hr" />
              
           
                <Nav.Link eventKey={4} href="/CABAL" className='buttons_navbarm'>CABAL</Nav.Link>
                <hr class="hr" />
                <Nav.Link href="https://twitter.com/JeetsOnBTC" className='buttons_navbarm'>
                <FaTwitter/>
                </Nav.Link>
              </Grid>
            </Grid>
            <hr class="hr" />
          </Nav>
        </Navbar.Collapse>
      </>
    </Navbar>
  );
}

export default CollapsibleExample;
