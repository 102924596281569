import React from 'react'
import { Grid } from '@mui/material'
import Runes1 from './Images/CABAL (1).png'
import Runes2 from './Images/CABAL.png'
import Navbar from './NavBar/NavDO'
import Tilt from 'react-parallax-tilt';
function Runes() {
  return (
    <div  className='makeaborder'> 
    <Navbar/>
    <div className='container'>
    <br/> <br/>
          <Grid container spacing={1}>
          
          <Grid item md={6} xs={6} lg={6} sm={6}>
          <img
        src={Runes1}
        alt="Runes"
        className="click-animation"
        style={{ width: "200px" }}
   
      />

</Grid>      <Grid item md={6} xs={6} lg={6} sm={6}>


</Grid>
          <Grid item md={12} xs={12} lg={12} sm={12}>
          <Tilt>

            
          </Tilt>
<img src={Runes2}  style={{width:"100%"}}/>

</Grid>




          </Grid>
</div></div>
   
  )
}

export default Runes