import React, { useState } from "react";


import { useMediaQuery } from "@mui/material";

import Desktop from "./CabalDesk";


import Mobile from "./CabalMobile";






const Home = () => {
 
  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("lg"));




  return (
    <div className="mainContent">


<div className="garden">
      {/* THIS JAVASCRIPT CODE IS FOR Header of the Park Page*/}

      {/* <Slider /> */}
      {!smallScreen && (
        <div className="desk">
        <Desktop/>
       
  
     </div>
      )}
      {/* THIS JAVASCRIPT CODE IS FOR the Slider used for the Desktop View when you press on CustomSlider with ctrl and right arrow you move towards the Slider Page Desktop View*/}
      {smallScreen && (
         <div className="mob">
         <Mobile/>
  
        
       </div>
      )}
      {/* THIS JAVASCRIPT CODE IS FOR the Slider used for the Mobile View when you press on MobileSlider with ctrl and right arrow you move towards the Slider Page Mobile View*/}
    </div>
     
      

   
    </div>
  );
};

export default Home;
