import React from 'react'
import { Grid } from '@mui/material'
import Runes1 from './Images/runestext.png'
import Runes2 from './Images/codingbox.gif'
import Navbar from './NavBar/NavDO'
import Tilt from 'react-parallax-tilt';
function Runes() {
  return (
    <div  className='makeaborder'> 
    <Navbar/>
    <div className='container'>
    <br/> <br/>
          <Grid container spacing={1}>
          <Grid item md={2} xs={12} lg={2} sm={12}>


</Grid>
<Grid item md={8} xs={12} lg={8} sm={12}>
  <Tilt>

  <img src={Runes1}  style={{width:"100%"}}/>
  </Tilt>


</Grid>
<Grid item md={2} xs={12} lg={2} sm={12}>


</Grid>
<Grid item md={3} xs={3} lg={3} sm={3}>


</Grid>
<Grid item md={6} xs={12} lg={6} sm={12}>
<img src={Runes2}  style={{width:"100%"}}/>

</Grid>
<Grid item md={3} xs={3} lg={3} sm={3}>


</Grid>


          </Grid>
</div></div>
   
  )
}

export default Runes