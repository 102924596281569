import React from 'react'
import jeets from './Images/jeets.png'
import onbitcoin from './Images/onbitcoin.png'
import Jeet from './Images/meet.png'
import { Grid } from '@mui/material'
import Navbar from './NavBar/NavbarMobile'
function Desktop() {
  return (
       <div  className='makeaborderm'> 
       <Navbar/>  <br/> 
       <div className='container'>
       
       <img src={jeets} style={{width:"200px"}}/>
       <br/> 
       <img src={onbitcoin} style={{width:"300px"}}/>
       <br/><br/>   
       <p className='title_wherem'>Where community, art, and conviction intertwine on-chain.</p>
<Grid container spacing={0}>

<Grid item md={2} lg={2} xs={4} sm={4}>
       
   
    
</Grid>
<Grid item md={2} lg={2} xs={8} sm={8}>
       <br/>   <br/>  <br/> 
    <p className='title_wheres'>NAME :     &nbsp; &nbsp; &nbsp; &nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;   JEETS ON BTC
    
    <p className='title_wheres'>CREATOR :     &nbsp; &nbsp; &nbsp; &nbsp;     JEETOSHI
    <p className='title_wheres'>NETWORK :     &nbsp; &nbsp; &nbsp; &nbsp;     ON-CHAIN
    <p className='title_wheres'>ARTIST :     &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;   &nbsp;&nbsp;   ANTONIE MINGO
    
    <p className='title_wheres'>TICKER:     &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;    ? . ? . ?</p>
    </p>
   
    </p>
    </p>
    
     </p>
    
</Grid>


</Grid>
  
       </div>
       <Grid item md={10} lg={10} xs={12} sm={12}><br/> <br/>  <br/>  
    <img src={Jeet} style={{width:"100%"}}/>
    </Grid>
       </div>
  )
}

export default Desktop